import React from 'react';
import { graphql } from 'gatsby';
import ExtendedContent from '../components/extendedContent';
import Seo from '../components/seo/Seo';

const ArticlesPage = ({ data: { allArticles, allData }, pageContext }) => {
	const activeSite = pageContext?.locale || '';
	const allModules = allData?.nodes?.[0]?.chooseModule || '';
	const seoData = allArticles?.nodes?.[0]?.seoSettings || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || '';
	const pageNumber = pageContext?.pageNumber || 0;
	const isFirstPaginationPage = pageNumber < 1;

	return (
		<>
			<Seo seoData={seoData} seoGlobal={seoGlobal} />
			<ExtendedContent
				data={allModules}
				activeSite={activeSite}
				allArticles={allArticles}
				isFirstPaginationPage={isFirstPaginationPage}
				pageContext={pageContext}
			/>
		</>
	);
};
export default ArticlesPage;

export const query = graphql`
	query ($skip: Int!, $limit: Int!, $locale: String, $articlesPageId: String) {
		allArticles: allDatoCmsArticle(
			filter: { locale: { eq: $locale } }
			sort: { fields: meta___createdAt, order: DESC }
			skip: $skip
			limit: $limit
		) {
			nodes {
				id
				singlePageName
				cardHeader
				cardDescription
				buttonText
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				meta {
					createdAt
				}
				cardPhoto {
					gatsbyImageData
					alt
				}
				content {
					value
					__typename
					links {
						__typename
						...singlePageLink
						...moduleSeeAlso
					}
					blocks {
						__typename
						...blockImageLarge
						...blockImageSmall
						...blockGallery
						...blockMargin
						...alignTextBlock
					}
				}
			}
		}
		allData: allDatoCmsSinglePage(filter: { id: { eq: $articlesPageId } }) {
			nodes {
				singlePageName
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				chooseModule {
					__typename
					...moduleChooseFeaturedArticle
					...moduleTopBanner
					...moduleSeeAlso
				}
			}
		}
	}
`;
